<template>
    <div>
        <button 
            :disabled="isDisabled" @click="$emit('onSubmit')"
            class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light"
        >
            <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <slot name="submit-btn-icon"></slot>
            {{ submitTxt }}
        </button>

        <button v-if="!isHideCancelBtn" @click="$emit('onCancel')" class="btn btn-outline-secondary mt-1 waves-effect">
            {{ cancelTxt }}
        </button>
    </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
    loading: {
        type: Boolean,
        default: false
    },
    onSubmit: {
        type: Function
    },
    onCancel: {
        type: Function,
        default: () => {}
    },
    isHideCancelBtn: {
        type: Boolean,
        default: false
    },
    submitTxt: {
        type: String,
        default: 'Submit'
    },
    cancelTxt: {
        type: String,
        default: 'Cancel'
    },
    disabled: {
        type: Boolean,
        default: false
    }
})

defineEmits(['onSubmit', 'onCancel']);

const isDisabled = computed(() =>{
    if(props.disabled || props.loading) return true
    return false
});
</script>