<template>

    <div class="row">

        <div class="col-12 col-md-12 note-head">

            <div class="note-title">
                <h4>
                    <span>{{ note.name }}</span>
                    <span v-if="note.date" class="note-date"> - {{ note.date }}</span>
                </h4>
            </div>

            <div class="note-btn" v-if="!note.isHideAddBtn">
                <button @click="onOpenModal(note, index)" type="button" class="btn btn-outline-primary btn-sm btn-block">
                    <i class="fas fa-plus"></i>
                </button>
            </div>

            <div class="note-btn" v-if="note.isEdited">
                <button @click="onClickToPrintRx" class="btn btn-secondary btn-sm">
                    <i class="fas fa-print"></i>
                </button>
            </div>

        </div>

        <hr>

        <div class="col-12 col-md-12 note-body">
            <div class="card">
                <div class="card-body">

                    <div :class="`${item.status === 'inactive' && 'disabled-element'}`" class="rx-body" v-for="(item, noteIndex) in note.selectedNotes" :key="noteIndex">

                        <div class="medicine">
                            <div class="medicine-name" :class="`${item.status === 'inactive' && 'text-with-divider'}`">
                                <span class="sr-no">{{ noteIndex + 1 }}. </span>
                                <span class="name">{{ item.name }}</span>
                            </div>
                            <div v-if="checkTerms(item)" @click="onRemoveNote(noteIndex, item)" class="action">
                                <i class="far fa-times-circle close"></i>
                            </div>
                        </div>

                        <div class="row px-2">
                            <div v-for="medNote in noteObj.medicineNotes" :key="medNote.value" :class="`${col} dose`">
                                <button @click="openRxMedicineModal(medNote, noteIndex, item)" type="button" class="btn btn-sm btn-block">
                                    <span v-if="!item[medNote.primeValue]">
                                        {{ medNote.name }}
                                        <i class="fas fa-plus"></i>
                                    </span>

                                    <span v-if="item[medNote.primeValue]">
                                        {{ item[medNote.primeValue] }}
                                    </span>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <MedicineNoteModal 
            v-if="isModalOpen"
            :noteObj="noteObj"
            @onHandleMedNote="onHandleMedNote"
            @onCloseModal="onCloseModal"
        />

    </div>

</template>

<script setup>
import { onMounted, reactive, ref } from "vue"
import { useStore } from "vuex";
import MedicineNoteModal from "./MedicineNoteModal.vue";

const props = defineProps({
    note: Object,
    index: Number,
    openModal: Function,
    medicineNotes: {
        type: Array,
        default: [],
    },
    from: {
        type: String,
        default: ''
    },
    onClickToPrintRx: {
        type: Function,
        default: () => {}
    }
})

const emit = defineEmits(['openModal', 'onClickToPrintRx'])
const store = useStore();
const col = ref('col-3 col-md-3');
const isModalOpen = ref(false);

const noteObj = reactive({
    currentTab: 'dose',
    medicineNotes: [],
    noteIndex: -1,
    selectedNote: '',
}) 

const onClickToPrintRx = () => {
    emit('onClickToPrintRx', { rxNote: props.note, index: props.index })
}

const checkTerms = (item) => {
    if(!props.from) return true;
    else if(props.from === 'doctorOrder' && item.status === 'active') return true;
    return false;
}

const onOpenModal = (note, index) => {
    emit('openModal', {note, index})
}

const onHandleMedNote = ({note, newNote}) => {
    const currentTab = noteObj.currentTab === 'investigation_remarks' ? 'remarks' : noteObj.currentTab;
    props.note.selectedNotes[noteObj.noteIndex][currentTab] = note.name;
    if(newNote){
        props.note.newNotesByDoctor.push(newNote);
    }
}

const openRxMedicineModal = (item, index, selectedNote) => {
    noteObj.currentTab = item.value;
    noteObj.noteIndex = index;
    noteObj.selectedNote = selectedNote;
    isModalOpen.value = true;
}

const onRemoveNote = (selectedIndex, item) => {
    if(item.isEdited){
        item.status = 'inactive'
        return;
    }
    props.note.selectedNotes.splice(selectedIndex, 1)
}

const setHideOrShowTabs = () => {
    noteObj.medicineNotes.length = 0;
    const filteredNotes = props.medicineNotes.filter(item => {
        item.primeValue = item.value
        if(item.value === 'investigation_remarks'){
            item.primeValue = 'remarks'
        }
        if (props.note.value === 'rx') {
            return item.value !== 'investigation_remarks';
        }
        return item.value === 'investigation_remarks';
    })
    noteObj.medicineNotes.push(...filteredNotes);
}

const onCloseModal = () => {
    isModalOpen.value = false;
}

onMounted(() => {
    setHideOrShowTabs()
    if(props.note.value === 'investigation'){
        col.value = 'col-12 col-md-12'
    }
})

</script>

<style scoped>
.note-title {
    width: 50%;
}
.note-date {
    font-size: 16px;
    margin-left: 2%;
}
.rx-body .row > *{
    padding: 0 !important;
}
.dose button:hover {
    color: #7367f0;
}
.remarks button:hover {
    color: #7367f0;
}
.duration button:hover {
    color: #7367f0;
}
.rx-body {
    padding-bottom: 8px;
}
.note-input{
    position: absolute;
    width: 78%;
    padding: 0.271rem 1rem;
    display: inherit;
    top: -5px;
    left: 3%;
}
.note-body .card-body{
    padding: 0 2%;
}
.note-head {
    display: flex;
    margin-top: 2%;
    justify-content: space-between;
    margin-bottom: 1%;
}
.action .close {
    color: red;
    cursor: pointer;
}
.note-body .card {
    background: #f9f9f9;
    min-height: 100px;
    margin-bottom: 1rem;
}
.medicine {
    display: flex;
    justify-content: space-between;
    margin: 7px 0;
    align-items: center;
    position: relative;
}
</style>