<template>
    <div class="modal fade show add-note-modal" aria-labelledby="addNewCardTitle">
        <div class="modal-dialog modal-dialog-centered modal-lg" ref="admissionModalRef">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between align-items-center px-1 my-1 align-item-center">
                            <h1 class="mb-0">{{ 'Add ' + note.name }}</h1>
                            <NoteSearch 
                                :title="note.name"
                                @onSelectNote="onSelectNote"
                                :noteType="note.value"
                                :selectedNotes="note.selectedNotes"
                            />
                            <button @click="closeModal" type="button" class="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                    </div>
                </div>

                <hr class="mt-0">
                
                <div class="modal-body">

                    <div class="row gy-2 px-2">

                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    <button type="button" class="btn" @click="onChangeTab('single')" :class="selectedTab === 'single' && 'bg'">
                                        {{ note.name }}
                                    </button>
                                    <button type="button" class="btn"  @click="onChangeTab('group')" :class="selectedTab === 'group' && 'bg'">
                                        {{ note.name }} groups
                                    </button>
                                </div>
                                <div class="card-body modal-card">
                                    <button 
                                        v-for="(preNote, index) in note.preAddedNotes" :key="preNote.id" 
                                        @click="onSelectNote(preNote, index)"
                                        class="btn cancel-subscription mt-1 waves-effect round med-title" 
                                        :class="preNote.isSelected ? 'btn-primary' : 'btn-outline-primary'">
                                        <i class="fas fa-check"></i>
                                        {{ preNote.name }}
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    </div>
</template>

<script setup>
import useDetectOutsideClick from '@/services/useDetectOutsideClick';
import { computed, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import NoteSearch from "./RxNoteSearch.vue";
import { useRoute } from "vue-router";

const store = useStore();
const $route = useRoute();
const props = defineProps({
    note: Object
})
const emit = defineEmits([])

const searchTxt = ref('');
const searchResults = ref([]);
const newNotes = reactive([]);
const admissionModalRef = ref(null);

//Functions and method

const loading = ref(false)
const newValue = ref('');
const selectedTab = ref('single');

const onChangeTab = (tab) => {
    selectedTab.value = tab;
}

const onSelectNote = (note) => {
    const newIndex = newNotes.findIndex(item => item.name === note.name);
    if(newIndex !== -1) {
        newNotes.splice(newIndex, 1);
    }
    if(newIndex < 0) {
        const newItem = makeMedicineObj(note);
        newNotes.push(newItem)
    }
    note.isSelected = !note.isSelected;
    saveNotes();

    const preAddedNote = props.note.preAddedNotes.find(item => item.name === note.name);
    if(preAddedNote){
        preAddedNote.isSelected = note.isSelected;
    }

}

const setInitialValue = () => {
    newNotes.length = 0;
    for(let i = 0; i < props.note.selectedNotes.length; i++){
        const preAddedNote = props.note.preAddedNotes.find(item => item.name === props.note.selectedNotes[i].name);
        if(preAddedNote){
            preAddedNote.isSelected = true;
        }
        newNotes.push(props.note.selectedNotes[i]);
    }
}

const makeMedicineObj = (product) => {
    const notes = {
        id: 0,
        product_id: product.id,
        name: product.name,
        remarks: '',
        isSelected: true,
    }

    if(props.note.value === 'rx'){
        notes['dose'] = '';
        notes['unit'] = '';
        notes['duration'] = '';
        notes['madication_status'] = '';
        notes['status'] = 'active';
        notes['order_date'] = '';
    }

    return notes;
}

const closeModal = () => {
    store.state.isModalOpenTwo = false;
}

const saveNotes = () => {
    props.note.selectedNotes.length = 0;
    props.note.selectedNotes.push(...newNotes)
}

onMounted(() => {
    setInitialValue();
})

</script>

<style scoped>
.modal-body{
    min-height: 400px;
}
.card-header {
    padding-top: 0%;
}
.card-header button {
    color: #7367f0;
    padding-left: 12%;
    padding-right: 12%;
    font-weight: bold;
}

.bg {
    background: aliceblue !important;
}
.note-search{
    width: 50%;
}
.med-title{
    padding: 0.486rem 1.5rem;
}
.modal-card{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-height: 280px;
    overflow-x: hidden;
    overflow-y: scroll;
}
.add-note-modal {
    display: block; 
    background: #1c1d1c33
}
</style>