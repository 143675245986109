<template>
    <div class="custome-search" id="custome-search" ref="dropdownRef">
        <div class="input-group input-group input-group-merge ms-1 w-100">
            <span class="input-group-text">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search text-muted">
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
            </span>
          <input type="text" class="form-control"
            id="chat-search" v-model="params.q" @input="onSearch" @focus="onFoucus"
            :placeholder="`Search ${title.toLowerCase()}`" aria-label="Search..."
            aria-describedby="chat-search"/>
        </div>

        <ul class="dropdown-search deep-shadow" @scroll="onScroll">
            <li
                @click="onHandleSelect(item, index)"
                v-for="(item, index) in searchResults" 
                :key="item.id" class="search-list-item">

                <div class="form-check">
                    <input v-model="item.isSelected" class="form-check-input" type="checkbox">
                    <label :class="item.isSelected && 'text-primary text-bold'" class="form-check-label">
                        {{ item.name }} (stock: {{ item.stock_in_hand }})
                    </label>
                </div>
            </li>
        </ul>
        
    </div>
</template>

<script setup>
import useDetectOutsideClick from '@/services/useDetectOutsideClick';
import handlePurchase from '@/services/modules/purchase';
import { onMounted, reactive, ref } from 'vue';
import { debounce } from 'lodash';
import { useRoute } from 'vue-router';

const props = defineProps(['title', 'onSelectNote', 'noteType','selectedNotes']);
const emit = defineEmits(['onSelectNote'])
const { fetchProducts } = handlePurchase();

const dropdownRef = ref(null)
const route = useRoute();
const products = reactive([]);
const searchResults = reactive([]);
const scrollHeight = ref(0);
const params = reactive({
    page: 1,
    per_page: 100,
    q: '',
    product_type: 'finished_goods',
    service_type: 'rx'
});

const onScroll = (e) => {
    let percent = (e.srcElement.scrollTop + e.srcElement.clientHeight) / e.srcElement.scrollHeight * 100;
    percent = parseInt(percent);
  
    if (percent == 90 && (scrollHeight.value != 90)) {
        params.page += 1;
        fetchProductList((result) => {
            searchResults.push(...checkSelected(result));
        });
    }
    scrollHeight.value = percent;
}

const checkSelected = (products) => {
  const selectedNotesMap = new Map(props.selectedNotes.map(item => [item.name, true]));
  
  return products.map(product => ({
    ...product,
    isSelected: selectedNotesMap.has(product.name)
  }));
};

useDetectOutsideClick(dropdownRef, () => {
    searchResults.length = 0;
})

const onHandleSelect = (item, index) => {
    emit('onSelectNote', item);
}

const onSearch = debounce((e) => {

    if(!params.q) return;
    searchResults.length = 0;
    products.length = 0;
    params.page = 1;

    fetchProductList((result) => {
        searchResults.push(...checkSelected(result));
    });

}, 300)

const onFoucus = async () => {
    if(products.length < 1){
        params.page = 1;
        await fetchProductList();
    }
    searchResults.length = 0;
    searchResults.push(...checkSelected(products));
}

const getQuery = () => {
    let query = `?company_id=${route.params.companyId}`;
    for(let key in params){
        query += `&${key}=${params[key]}`;
    }
    return query;
}

const fetchProductList = async (callback = () => {}) => {
    const query = getQuery();
    await fetchProducts(query).then(res=> {
        if(!res.data) return
        products.push(...res.data.data);
        callback(res.data.data);
    })
}

onMounted( async () => {
    if(props.noteType === 'investigation'){
        params.product_type = 'services';
        params.service_type = 'investigation';
    }
    await fetchProductList();
})
</script>

<style scoped>
.custome-search{
    position: relative;
    width: 50%;
}
.search-list-item:last-child {
    border: none;
}
.search-list-item {
    display: flex;
    align-items: center;
    padding: 5px;
    margin: 5px;
    border-bottom: 1px solid #f1f1f1;
    cursor: pointer;
}
.search-list-item:hover {
    background: #f9f7f7;
}
.dropdown-search {
    position: absolute;
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
    left: 4%;
    padding-left: 0;
    background: #ffff;
    z-index: 99999;
}

</style>